import React from 'react'
import Images from '../../Data/Images'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";

const Unlockaccount = () => {
  return (
    <div className='Forgetpassword'>
        <div className="fp-logo">
            <img src={Images.logo} alt="logo" />
        </div>
        <div className="main-forget">
            <span>Unlock account</span>
            <p>Enter your email address to reset your password</p>
            <input type="email" placeholder='email' />
            <a href="Login" className="reset">Reset via Email</a>
            <a href="Login" className="backto"><FontAwesomeIcon icon={faArrowAltCircleLeft} />Back to Log In</a>
        </div>
    </div>
  )
}

export default Unlockaccount