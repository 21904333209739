import React from "react";
import "./Termsandconditions.css";

const Termsandconditions = () => {
  return (
    <div className="privacypolicy">
      <span>Terms and Conditions</span>
      <br />
      <span1>Last Updated: 5 July 2024</span1>
      <p>
      Welcome to Space Internationals. By accessing and using our website [www.spaceinternationals.com] and our services, you agree to comply with and be bound by the following terms and conditions ("Terms"). Please read these Terms carefully before using our website and services. If you do not agree with any part of these Terms, you must not use our website or services.
<br />
<br />
<span1>1. Introduction</span1>
<br />
<br />
1.1 Space Internationals ("we," "us," "our") provides immigration visa processing services.
<br />
<br />
1.2 These Terms govern your use of our website and services, including any content, functionality, and services offered on or through our website.
<br />
<br />
<span1>2. Eligibility</span1>
<br />
<br />
2.1 You must be at least 18 years old to use our services.
<br />
<br />
2.2 If using our services on behalf of an organization, you must have the authority to bind that organization to these Terms.
<br />
<br />
<span1>3. Services Provided</span1>
<br />
<br />
3.1 We offer a range of services related to immigration visa processing, including consultation, application assistance, and documentation review.
<br />
<br />
3.2 We reserve the right to modify or discontinue any part of our services at any time without notice.
<br />
<br />
<span1>4. User Responsibilities</span1>
<br />
<br />
4.1 You agree to use our website and services only for lawful purposes and in accordance with these Terms.
<br />
<br />
4.2 You must provide accurate, current, and complete information about yourself and maintain and promptly update your information.
<br />
<br />
<span1>5. Account Security</span1>
<br />
<br />
5.1 You are responsible for maintaining the confidentiality of your account and password.
<br />
<br />
5.2 Notify us immediately of any breach of security or unauthorized use of your account.
<br />
<br />
<span1>6. Fees and Payment</span1>
<br />
<br />
6.1 You agree to pay all fees and charges associated with your use of our services according to the pricing terms presented at the time of purchase.
<br />
<br />
6.2 All payments must be made in [currency], and you are responsible for any foreign transaction fees or other charges incurred.
<br />
<br />
<span1>7. Intellectual Property Rights</span1>
<br />
<br />
7.1 All content on our website, including text, graphics, logos, images, and software, is the property of Space Internationals or its content suppliers.
<br />
<br />
7.2 You are granted a limited, non-exclusive, non-transferable, and revocable license to access and use our website and services for your personal, non-commercial use.
<br />
<br />
<span1>8. Prohibited Uses</span1>
<br />
<br />
8.1 You shall not use our website or services for any illegal or unauthorized purpose.
<br />
<br />
8.2 You shall not interfere with or disrupt the integrity or performance of our website or services.
<br />
<br />
<span1>9. Privacy Policy</span1>
<br />
<br />
9.1 Your use of our website and services is also governed by our Privacy Policy, which is incorporated herein by reference.
<br />
<br />
<span1>10. Third-Party Links</span1>
<br />
<br />
10.1 Our website may contain links to third-party websites. We are not responsible for the content or practices of these websites.
<br />
<br />
<span1>11. Disclaimers</span1>
<br />
<br />
11.1 Our website and services are provided on an "as-is" and "as-available" basis without warranties of any kind.
<br />
<br />
11.2 We do not warrant that our services will meet your requirements or that they will be uninterrupted, timely, secure, or error-free.
<br />
<br />
<span1>Limitation of Liability</span1>
<br />
<br />
12.1 To the fullest extent permitted by law, Space Internationals shall not be liable for any indirect, incidental, special, consequential, or punitive damages.
<br />
<br />
<span1>Indemnification</span1>
<br />
<br />
13.1 You agree to indemnify, defend, and hold harmless Space Internationals, its officers, directors, employees, agents, and third parties from any claims, liabilities, damages, and expenses arising out of your use of our services.
<br />
<br />
<span1>Governing Law</span1>
<br />
<br />
14.1 These Terms shall be governed by and construed in accordance with the laws of [Your Country], without regard to its conflict of law principles.
<br />
<br />
<span1>Dispute Resolution</span1>
<br />
<br />
15.1 Any disputes arising out of or in connection with these Terms shall be resolved through negotiation, mediation, or arbitration in [Your Country].
<br />
<br />
<span1>Termination</span1>
<br />
<br />
16.1 We reserve the right to terminate or suspend your account and access to our services at any time, without prior notice, for conduct that we believe violates these Terms or is harmful to other users of our website or services.
<br />
<br />
<span1>Modifications to Terms</span1>
<br />
<br />
17.1 We may revise these Terms from time to time. The most current version will always be posted on our website. By continuing to use our services after changes are made, you agree to be bound by the revised Terms.
<br />
<br />
<span1>Entire Agreement</span1>
<br />
<br />
18.1 These Terms, together with our Privacy Policy and any other legal notices published on our website, constitute the entire agreement between you and Space Internationals regarding your use of our services.
<br />
<br />
<span1>Severability</span1>
<br />
<br />
19.1 If any provision of these Terms is found to be invalid or unenforceable by a court, the remaining provisions will remain in full force and effect.
<br />
<br />
<span1>Waiver</span1>
<br />
<br />
20.1 No waiver of any term of these Terms shall be deemed a further or continuing waiver of such term or any other term, and our failure to assert any right or provision under these Terms shall not constitute a waiver of such right or provision.
<br />
<br />
<span1>Contact Information</span1>
<br />
<br />
21.1 If you have any questions about these Terms, please contact us at:
<br />
<br />
By email: [help@spaceinternationals.com] <br />
By mail: [Space Internationals, Old Bishan Nagar, Main Market, Street no. 8, Patiala, India, 147001]

      </p>
    </div>
  );
};

export default Termsandconditions;
