import React from "react";
import "./Contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedinIn,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

const Contact = () => {
  return (
    <div className="Contact">
      <div className="support-heading">
        <span>Contact Us</span>
      </div>
      <div className="contact-para">
        <p>
          We’re here to help. Don’t hesitate to get in touch with our expert
          support team who can help answer all of your questions.
        </p>
      </div>
      <div className="contact-grid">
        <div className="section-grid">
          <span>Email</span>
          <a href="mailto: help@spaceinternationals.com">help@spaceinternationals.com</a>
          <div className="section-line"></div>
          <p>Please Email Us with<br /> your inquiries.</p>
        </div>
        <div className="section-grid">
          <span>Our Manager</span>
          <a href="tel: 9574895790">+91 95748 95790</a>
          <div className="section-line"></div>
          <p>Monday-Saturday <br /> 10AM - 6PM</p>
        </div>
        <div className="section-grid">
          <span>Office</span>
          <a href="tel: 9574895792">+91 95748 95792</a>
          <div className="section-line"></div>
          <p>Monday-Saturday <br />10AM - 6PM</p>
        </div>
      </div>
      {/* <div className="contact-para">
        <p>
          If you are an existing customer and have a question, please contact
          your Account Representative for assistance. Existing customers can
          also utilize 24/7 Live Chat and WhatsApp text support. Please log in
          to your ApplyBoard account for details. For questions about a student
          application, please leave a message in the Notes section of the
          application, and our Customer Experience Team will be in touch.
        </p>
      </div> */}
      <div className="contact-social">
        <div className="social-tags">
          <a href="">
            {" "}
            <FontAwesomeIcon icon={faLinkedinIn} />{" "}
          </a>
        </div>
        <div className="social-tags">
          <a href="">
            {" "}
            <FontAwesomeIcon icon={faFacebook} />{" "}
          </a>
        </div>
        <div className="social-tags">
          <a href="">
            {" "}
            <FontAwesomeIcon icon={faInstagram} />{" "}
          </a>
        </div>
        <div className="social-tags">
          <a href="https://www.youtube.com/channel/UCeXH850dFrkyyXPlZt6PL4w">
            {" "}
            <FontAwesomeIcon icon={faYoutube} />{" "}
          </a>
        </div>
      </div>
      <div className="contact-map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3443.4093656834243!2d76.41104757566343!3d30.339313704539606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3910285efe9e8985%3A0x8dbbef296888cdfa!2sSPACE%20INTERNATIONALS!5e0!3m2!1sen!2sin!4v1714643921421!5m2!1sen!2sin"
          width="95%"
          height="500"
          allowfullscreen=""
          loading="lazy"
          className="mapp"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default Contact;
