import React from "react";
import "./Navbar.css";
import Images from "../../Data/Images";
import { GiHamburgerMenu, GiCrossMark } from "react-icons/gi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const Navbar = () => {
  const [togglemenu, settogglemenu] = React.useState(false);

  return (
    <nav className="navbar">
      <div className="nav-logo">
        <img src={Images.logo} alt="logo" />
      </div>

      <div className="nav-links">
        <ul>
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/Clients">Clients</a>
          </li>
          <li>
            <a href="/Partners">Partners</a>
          </li>
          <li>
            <a href="/Institutions">Institutions</a>
          </li>
        </ul>
      </div>

      {/* <div className="nav-btns">
        <a href="/si/student/login.php">Log In</a>
        <a href="/si/student/register.php">Register</a>
      </div> */}

      <div className="nav-btns">
        <div class="dropdown">
          <button class="dropbtn">Log In</button>
          <div class="dropdown-content">
            <a href="/si/student/login.php">Login as Client</a>
            <a href="/si/partner/login.php">Login as Partner</a>
          </div>
        </div>

        <div class="dropdown">
          <button class="dropbtn">Register</button>
          <div class="dropdown-content">
            <a href="/si/student/register.php">Register as Client</a>
            <a href="/Resumeform">Register as Partner</a>
          </div>
        </div>
      </div>

      <div className="navbar-smallscreen">
        <GiHamburgerMenu
          color="#0e5094"
          className="navbar-menu"
          fontsize={50}
          onClick={() => {
            settogglemenu(true);
          }}
        />
        {togglemenu && (
          <div className="navbar-smallscreen_overlay flex__center slide-bottom">
            <GiCrossMark
              color="#ffffff"
              className="overlay__close"
              onClick={() => {
                settogglemenu(false);
              }}
            />
            <ul className="navbar-smallscreen_links">
              <li>
                <a
                  href="/"
                  onClick={() => {
                    settogglemenu(false);
                  }}
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="/Clients"
                  onClick={() => {
                    settogglemenu(false);
                  }}
                >
                  Clients
                </a>
              </li>
              <li>
                <a
                  href="/Partners"
                  onClick={() => {
                    settogglemenu(false);
                  }}
                >
                  Partners
                </a>
              </li>
              <li>
                <a
                  href="/Institutions"
                  onClick={() => {
                    settogglemenu(false);
                  }}
                >
                  Institutions
                </a>
              </li>
            </ul>
            {/* <div className="nav-line"></div> */}
            <div className="nav-small-btns">
              <div class="dropdown">
                <button class="dropbtn">Log In</button>
                <div class="dropdown-content">
                  <a href="/si/student/login.php">Login as Client</a>
                  <a href="/si/partner/login.php">Login as Partner</a>
                </div>
              </div>

              <div class="dropdown">
                <button class="dropbtn">Register</button>
                <div class="dropdown-content">
                  <a href="/si/student/register.php">Register as Client</a>
                  <a href="/Resumeform">Register as Partner</a>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
