import React from "react";
import "./Partners.css";
import Images from "../../Data/Images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faWindowRestore,
  faDatabase,
  faSheetPlastic,
} from "@fortawesome/free-solid-svg-icons";

const Partners = () => {
  return (
    <div className="Partners">
      <div className="partner-main">
        <div className="partner-content">
          <span>Let's Start a New Journey - We're Here to See you Grow.</span>
          <p>
            Space Internationals is more than a platform. It’s your trusted partner to
            help you do best for your clients: Lets fulfill their international dreams together.
          </p>
          <a href="/Resumeform">Partner With Us</a>
        </div>
        <div className="partner-img">
          <img src={Images.partnersfirst} alt="" />
        </div>
      </div>

      <div className="growth">
        <div className="growth-grid">
          <div className="grid-grow">
            <div className="growth-img">
              <img src={Images.logo} alt="" />
            </div>
            <div className="growth-content">
              <span1>Growth</span1>
              <span>Grow Quicker with Lower Costs</span>
              <p>
                Space Internationals Recruitment Partners have grown more than 5X
                in the last 5 years.
              </p>
            </div>
          </div>
          <div className="grid-grow">
            <div className="growth-img">
              <img src={Images.logo} alt="" />
            </div>
            <div className="growth-content">
              <span1>Assured Payout</span1>
              <span>Trusted and Transparent Payments</span>
              <p>
                We pay you at multiple stages of the application and enrollment
                process.
              </p>
            </div>
          </div>
          <div className="grid-grow">
            <div className="growth-img">
              <img src={Images.logo} alt="" />
            </div>
            <div className="growth-content">
              <span1>Access</span1>
              <span>More Institutions Globally</span>
              <p>
                More than 1,500 educational institutions across Canada, the
                United Kingdom, the United States, Australia, and Ireland.
              </p>
            </div>
          </div>
        </div>

        <div className="growth-grid1">
          <div className="grid-grow">
            <div className="growth-img">
              <img src={Images.logo} alt="" />
            </div>
            <div className="growth-content">
              <span1>Offer More</span1>
              <span>One Platform For Every Need</span>
              <p>
                With Space Internationals discover exclusive opportunities to
                offer value and earn more.
              </p>
            </div>
          </div>
          <div className="grid-grow">
            <div className="growth-img">
              <img src={Images.logo} alt="" />
            </div>
            <div className="growth-content">
              <span1>Insights</span1>
              <span>Industry-leading Insights and Training</span>
              <p>
                Receive best-in-class training on Education Industry and
                Insights to future-proof their business.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="commissions">
        <div className="perk-commissions">
          <div className="support-heading">
            <span>Access More Institutions, Perks, and Faster Commissions</span>
          </div>
          <div className="support-para">
            <p>
              Here to help you grow your business by offering the best
              opportunities and support.
            </p>
          </div>
          <div className="perk-grid">
            <div className="perk">
              <div className="img-perk">
                <img src={Images.perks1} alt="" />
              </div>
              <div className="perk-content">
                <span>Best Commissions</span>
                <p>
                  Quick and reliable commission so you get paid for your hard
                  work.
                </p>
              </div>
            </div>
            <div className="perk">
              <div className="img-perk">
                <img src={Images.perks2} alt="" />
              </div>
              <div className="perk-content">
                <span>Perks and Rewards</span>
                <p>
                  Earn bonuses and access top training to support your growth.
                </p>
              </div>
            </div>
            <div className="perk">
              <div className="img-perk">
                <img src={Images.perks3} alt="" />
              </div>
              <div className="perk-content">
                <span>1,500+ Institutions</span>
                <p>
                  Access top programs at top institutions in the most desirable
                  destinations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="easyuse">
        <div className="easytouse">
          <div className="support-heading">
            <span>
              An Easy-to-Use Platform that Connects You to the Right Programs
            </span>
          </div>
          <div className="easy-platform">
            <div className="easy-grid">
              <FontAwesomeIcon icon={faClock} className="i" />
              <span>Find Programs Faster</span>
            </div>
            <div className="easy-grid">
              <FontAwesomeIcon icon={faSheetPlastic} className="i" />
              <span>One Easy Application</span>
            </div>
            <div className="easy-grid">
              <FontAwesomeIcon icon={faWindowRestore} className="i" />
              <span>Central Platform</span>
            </div>
            <div className="easy-grid">
              <FontAwesomeIcon icon={faDatabase} className="i" />
              <span>Data Driven Insights</span>
            </div>
          </div>
        </div>
      </div>

      <div className="ecosystem">
        <div className="support-heading">
          <span>
            We're Invested in Caring for You and the Ecosystem of International
            Education
          </span>
        </div>
        <div className="ecosystem-caring">
          <div className="ecosystem-grid">
            <div className="eco-img">
              <img src={Images.local} alt="" />
            </div>
            <div className="eco-content">
              <span>Local Support</span>
              <p>
                Our recruitment experts are there to support you
                every step of the way.
              </p>
            </div>
          </div>
          <div className="ecosystem-grid">
            <div className="eco-img">
              <img src={Images.scholar} alt="" />
            </div>
            <div className="eco-content">
              <span>Education</span>
              <p>
                Expand your knowledge and stay ahead of your competitors with
                guided online courses and certificates.
              </p>
            </div>
          </div>
          <div className="ecosystem-grid">
            <div className="eco-img">
              <img src={Images.events} alt="" />
            </div>
            <div className="eco-content">
              <span>Events</span>
              <p>
                Training and Regular events to keep you
                updated on the latest trends and regulations.
              </p>
            </div>
          </div>
          <div className="ecosystem-grid">
            <div className="eco-img">
              <img src={Images.datainsight} alt="" />
            </div>
            <div className="eco-content">
              <span>Data and Insights</span>
              <p>
                Industry-leading insights and knowledge to help you plan,
                expand, and achieve your goals.
              </p>
            </div>
          </div>
        </div>
        <div className="ecosystem-tag">
          <a href="/Resumeform">Let's Get Started</a>
        </div>
      </div>

      <div className="relationship">
        <div className="support-heading">
          <span>A Relationship Built on Trust and Credibility</span>
        </div>
        <div className="testo-slider">
          <div className="testo-img">
            <img src={Images.logo} alt="" />
          </div>
          <div className="testo-content">
            <p>
              "Space Internationals has helped me significantly increase my
              student reach and gives me more school options to offer my
              students. I also have added advantage of Space Internationals
              assessing student eligibility, which gives me and my students
              peace of mind."
            </p>
            <span>Dhruv Asth</span>
          </div>
        </div>
      </div>

      {/* <div className="global-presence">
        <div className="support-heading">
          <span>Global Presence for Global Recruitment</span>
        </div>
        <div className="global">
          <div className="global-content">
            <div className="span-container">
              <span>1,200+</span>
              <p>Globally</p>
            </div>
            <div className="span-container">
              <span>250+</span>
              <p>South Asia</p>
            </div>
            <div className="span-container">
              <span>25+</span>
              <p>South and East Asia, Africa and Latin America</p>
            </div>
          </div>
          <div className="global-img">
            <img src={Images.map1} alt="" />
          </div>
        </div>
      </div> */}

      <div className="last-section">
        <div className="contact-container">
          <div className="container-span">
            <span>Join 500+ Recruitment Partners</span>
          </div>
          <div className="container-tag">
            <a href="/Resumeform">Partner with Us</a>
          </div>
        </div>

        <div className="wrapper-stat">
          <div className="stat-grid">
            <div className="stats">
              <img src={Images.logo} alt="" />
              <span>100,000+</span>
            </div>
            <div className="stats-para">
              <p>Clients <br />Helped</p>
            </div>
          </div>
          <div className="stat-grid">
            <div className="stats">
              <img src={Images.logo} alt="" />
              <span>95%</span>
            </div>
            <div className="stats-para">
              <p>Success <br />Rate</p>
            </div>
          </div>
          <div className="stat-grid">
            <div className="stats">
              <img src={Images.logo} alt="" />
              <span>1,000+</span>
            </div>
            <div className="stats-para">
              <p>Institution <br />Partnerships</p>
            </div>
          </div>
          <div className="stat-grid">
            <div className="stats">
              <img src={Images.logo} alt="" />
              <span>$1M+</span>
            </div>
            <div className="stats-para">
              <p>Helped Students Access $1M+ in Scholarships</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
