export const Faqdata = [
    {
      Question:
        "What services do you offer?",
      Answer:
        "We give exact consultation as per clients requirement i.e. About their career as Student, as Spouse, as Visitor, as Tourist or as PR in any Country.",
    },
    {
      Question: "What is the consultation process like?",
      Answer:
        "Consultation Means getting advice related to our file. We as consultant don't make any document related to our clients. Client themselves provide coloured photocopy (Notarized) of all the required documents (Without giving original documents) for further processing of their visa file.",
    },
    {
      Question: "How much do your services cost?",
      Answer:
        "A country’s visa policy is a set of rules that determines who is allowed and who is not allowed to enter the country. The policy may allow passport holders from one nation to enter without a visa, but not those from another. The majority of visa rules are bilateral, meaning that two countries will enable their nationals to travel visa-free, however this is not always the case. When it comes to setting visa policies, there are no hard and fast laws. However, diplomatic links with the other country, the history of illegal immigration from the country, and cost and tourism factors are some determining factors.",
    },
    {
      Question: "How do I get started with your services?",
      Answer:
        "To start with our services first you have to register (create an account) on our website and fill all the required information. When you have registered then Our Team will Reach you for Further Processing or You can Visit our Office between 10:30Am to 6:00Pm (Monday to Saturday). Note:- Visa limitations are in place to keep track of and control the flow of visitors in and out of a nation, as well as to prevent illegal immigration and other criminal activity. By requiring travelers to apply for a visa, the authorities are able to thoroughly scrutinize potential visitors. Individuals may, for example, attempt to migrate to a country with superior economic chances in order to work there illegally.",
    },
    {
      Question: "What is your success rate with visa applications?",
      Answer:
        "Clients get their visa approval based on their Profile and If your profile meets the criteria of the concerned immigartion law of that country then your visa can be approved or can get rejection if creteria does not meet. Note:- If any fake document is traced by Immigration Officer, In that case Client can get Lifetime Ban and The Responsibility will be of clients as all documnets are provided by Client themselves.",
    },
  ];
  