import React from "react";
import "./Home.css";
import Images from "../../Data/Images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWindowRestore,
  faHeadphonesSimple,
} from "@fortawesome/free-solid-svg-icons";
import { faGoogleScholar } from "@fortawesome/free-brands-svg-icons";
import GetStarted from "../../Commons/GetStarted/GetStarted";

const Home = () => {
  return (
    <div className="Home">
      <div className="header">
        <div className="videoheader">
          <video autoPlay loop muted className="video-background">
            <source src={Images.backvideo} type="video/mp4" />
          </video>
        </div>
        <div className="header-content">
          <span>We are 100% Transparent Company.</span>
          <a href="/si/student/register.php" className="visa">
            Click Here to Apply
          </a>
          <div className="header-tags">
            <a href="/Partners">Want to Become our Partner?</a>
            <a href="/Institutions">I work at an Academic institution</a>
          </div>
          <span>Our Moto is Honesty and Transparency.</span>
        </div>
      </div>

      <div className="mainfeatures">
        <div className="features-heading">
          <span>A Platform that you can Trust with your Eyes Closed.</span>
        </div>
        <div className="features">
          <div className="feature">
            <p>We don't take Original Documents of our Clients.</p>
          </div>
          <div className="feature">
            <p>We Don't Take College/University Fees in our Account.</p>
          </div>
          <div className="feature">
            <p>
              We Do Live Visa Submission Process and Handover a Copy to Client.
            </p>
          </div>
          <div className="feature">
            <p>We handover Clients their file to read and then Sign it.</p>
          </div>
        </div>
      </div>

      <div className="support">
        <div className="support-heading">
          <span>A Platform that Supports you End-to-End</span>
        </div>
        <div className="support-section">
          <div className="student-support">
            <div className="support-img">
              <img src={Images.Client} alt="Client" />
            </div>
            <div className="support-content">
              <span>Clients</span>
              <p>
                We are committed to your dreams and strive tirelessly to bring
                them to life. Connect with and apply to study abroad or any
                other visa services.
              </p>
              <a href="/Clients">Learn More</a>
            </div>
          </div>
          <div className="student-support">
            <div className="support-img">
              <img src={Images.partners} alt="partners" />
            </div>
            <div className="support-content">
              <span>Partners</span>
              <p>
                Space Internationals is more than just a platform. We're your
                reliable partner, dedicated to assisting you in what you excel
                at: helping clients worldwide achieve their dreams.
              </p>
              <a href="/Partners">Learn More</a>
            </div>
          </div>
          <div className="student-support">
            <div className="support-img">
              <img src={Images.institutions} alt="Institute" />
            </div>
            <div className="support-content">
              <span>Institutions</span>
              <p>
                Expand your global reach and attract more qualified
                applicants through a single, user-friendly platform trusted by
                over institutions worldwide.
              </p>
              <a href="/Institutions">Learn More</a>
            </div>
          </div>
        </div>
      </div>

      <div className="help">
        <div className="help-container">
          <div className="support-heading">
            <span>How We Help</span>
          </div>
          <div className="video-help">
            <img src={Images.Client} alt="" />
          </div>
          <div className="help-tag">
            <a href="/Resumeform">Partner With Us</a>
          </div>
        </div>
      </div>

      <div className="admission">
        <div className="addmission-container">
          <div className="support-heading">
            <span>We Give Clients the 100% Transparent and Loyal Services</span>
          </div>
          <div className="video-help">
            <img src={Images.Client} alt="" />
          </div>
          <div className="lists-container">
            <div className="list-wrapper">
              <FontAwesomeIcon icon={faWindowRestore} className="icon" />
              <span>
                Find Programs <br />
                Faster
              </span>
            </div>
            <div className="list-wrapper">
              <FontAwesomeIcon icon={faHeadphonesSimple} className="icon" />
              <span>
                Helpful and
                <br /> Dedicated Support
                <br /> Team
              </span>
            </div>
            <div className="list-wrapper">
              <FontAwesomeIcon icon={faGoogleScholar} className="icon" />
              <span>
                Access to Exclusive
                <br /> Scholarships
              </span>
            </div>
          </div>
          <div className="ad-tag">
            <a href="/si/student/register.php">Try It Today</a>
          </div>
        </div>
      </div>

      <div className="getstartedwith">
        <GetStarted />
      </div>
    </div>
  );
};

export default Home;
