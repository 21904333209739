import React from 'react'
import './About.css'
import Images from '../../Data/Images'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faWindowRestore,
  faDatabase,
  faSheetPlastic,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import GetStarted from "../../Commons/GetStarted/GetStarted";

const About = () => {
  return (
    <div className='About'>
      <div className="partner-main">
        <div className="partner-content">
          <span>We're Space Internationals</span>
          <p>
          We are 100% Transparent & Loyal Company
          </p>
          <a href="">Learn How</a>
        </div>
        <div className="partner-img">
          <img src={Images.ourstory} alt="" />
        </div>
      </div>

      <div className="our-story">
        <div className="ourstory-img">
          <div className="osimg"></div>
          <div className="osimg"></div>
          <div className="osimg"></div>
          <div className="osimg"></div>
        </div>
        <div className="ourstory-content">
          <span>Our Story</span>
          <p>Space Internationals newly formed online platform simplifies the study abroad search, application, and acceptance process by connecting international students, recruitment teams, freelancers and academic institutions on one platform.</p>
          <p>Founded in 2018 by Narang family i.e. Amandeep Narang and Kusum Narang, we’ve built partnerships with 1,000+ primary, secondary, and post-secondary educational institutions, and work with recruitment teams to drive diversity on campuses across Canada, the United States, the United Kingdom, Australia, and Europe(Schengen).</p>
          <p>Space Internationals is growing to become the world’s largest online platform for international student recruitment, assisting thousands of students to be successful in their life and can get best results in with their educational journeys as well as in their dream carrier. Year 2024, will be the Space Internationals named as one of the fastest-growing technology companies in India as well as at Global level.</p>
          <p>Our team has grown quickly over the past six years, and we now have 100+ team members across the India and globe. The Space Internationals headquarters is located in Patiala, Punjab, India with representatives and is going to expend their team network in more than 30 other countries including India, Canada, China, Vietnam, the Philippines, Nepal, Bangladesh, the United Kingdom, Australia, and the United States.</p>
          {/* <a href="">Learn More About Our Leaders &nbsp; <FontAwesomeIcon icon={faArrowCircleRight} /></a> */}
        </div>
        <div className="ourstory-img">
          <div className="osimg"></div>
          <div className="osimg"></div>
          <div className="osimg"></div>
          <div className="osimg"></div>
        </div>
      </div>

      <div className="ourvalues">
        <div className="support-heading">
          <span>At Space Internationals, We're Committed to Our Values</span>
        </div>
        <div className="ourvalues-grid">
          <div className="value-grid">
            <img src={Images.studentsuccess} alt="studentsuccess" />
            <span>Helping Students Achieve Success</span>
          </div>
          <div className="value-grid">
            <img src={Images.care} alt="care" />
            <span>Caring About Each Other</span>
          </div>
          <div className="value-grid">
            <img src={Images.customerexp} alt="customerexp" />
            <span>Delivering A+ Customer Experience</span>
          </div>
          <div className="value-grid">
            <img src={Images.contract} alt="contract" />
            <span>Taking Ownership</span>
          </div>
          <div className="value-grid">
            <img src={Images.innovating} alt="innovating" />
            <span>Innovating and Improving</span>
          </div>
          <div className="value-grid">
            <img src={Images.workfun} alt="workfun" />
            <span>Making Work Fun</span>
          </div>
        </div>
      </div>

      <div className="help">
        <div className="help-container">
          <div className="support-heading">
            <span>How We Help</span>
          </div>
          <div className="video-help">
            <img src={Images.Client} alt="" />
          </div>
          <div className="help-tag">
            <a href="/Resumeform">Partner With Us</a>
          </div>
        </div>
      </div>

      {/* <div className="global-presence">
        <div className="support-heading">
          <span>Team Members Worldwide</span>
        </div>
        <div className="support-para">
          <p>Our 1,200+ team members (and growing) are as diverse as the students that we support!</p>
        </div>
        <div className="global">
          <div className="global-content">
            <div className="span-container">
              <span>1,200+</span>
              <p>Globally</p>
            </div>
            <div className="span-container">
              <span>250+</span>
              <p>South Asia</p>
            </div>
            <div className="span-container">
              <span>25+</span>
              <p>South and East Asia, Africa and Latin America</p>
            </div>
          </div>
          <div className="global-img">
            <img src={Images.Institute} alt="" />
          </div>
        </div>
      </div> */}

      <div className="fastfacts">
        <div className="factsgrid">
          <div className="fastgrid">
            <img src={Images.logo} alt="" />
            <span>70,000+</span>
            <p>Students Assisted</p>
          </div>
          <div className="fastgrid">
            <img src={Images.logo} alt="" />
            <span>1,000+</span>
            <p>Partner Schools</p>
          </div>
          <div className="fastgrid">
            <img src={Images.logo} alt="" />
            <span>1,500+</span>
            <p>Recruitment Partners</p>
          </div>
          <div className="fastgrid">
            <img src={Images.logo} alt="" />
            <span>600+</span>
            <p>Team Members Globally</p>
          </div>
        </div>
      </div>

      <div className="getstarted">
        <GetStarted/>
      </div>

    </div>
  )
}

export default About