import React from "react";
import "./Refundpolicy.css";

const Refundpolicy = () => {
  return (
    <div className="refund">
      <span>
        Space Internationals (Space Visa and International Tours Services
        Private Limited)
      </span>
      <br />
      <br />
      <span1>Consultation Fee</span1>
      <p>
        We are committed to helping applicants from any part of the world access
        the opportunity for education or as visitor or as tourist or as spouse.
        At the same time, we want to ensure we deliver students or as visitor or
        as tourist or as spouse with a high chance of visa approval.
        Accordingly, Space International gives consultation to their clients and
        will collect a mandatory below mentioned consultation fee (the
        “consultation Fee”) from any applicant that contacts Space
        Internationals for any type of consultation (excluding work visa as
        Space Internationals don’t give work visa consultation) any one of the
        following criteria as defined by Space Internationals (which are subject
        to change from time to time in Space Internationals’ sole discretion):
        Space Internationals (Space Visa and International Tours Services
        Private Limited)
      </p>
      <span1>ONE TIME CONSULTATION FEE: </span1>
      <span1>PROCESSING FEE:</span1>
      <p>
        Space Internationals (Space Visa & International Tours Services Private
        Limited), does not charge any fee from their clients to process their
        visa files. Means to say that Space Internationals (Space Visa &
        International Tours Services Private Limited) helps their clients to
        submit their visa file free cost i.e. without payment.
      </p>
      <p>
        Note 1: Space Internationals reserves the right to modify, refund or
        waive the Consultation Fee in its sole discretion for any reason. The
        Processing Fee may be added at any point in the application process in
        Space Internationals’ sole discretion. Your use of the Space
        Internationals Solution following such a requirement and/or change
        constitutes your acceptance of any new or increased charges.
      </p>
      <p>
        Note 2: Space Internationals may also charge a non-refundable deposit
        for certain programs which shall be determined at its sole discretion.
      </p>
      <p>
        Note 3: The consultation Fee does not apply to students who are already
        present in the host country of study and/or present in the host country
        under a current student visa and applying to another program in the same
        country.
      </p>
      <p>
        Note 4: Every student needs to pay application fee of college /
        university as per rules and regulations.
      </p>
      <span1>Refund Policy</span1>
      <p>
        Refunds will NOT be issued in any of the following cases: <br />
        An applicant has taken our consultation from our team.
        <br />
        An applicant fails to provide any required document to process the
        application;
        <br />
        An applicant’s visa application is approved, rejected; or banned from
        any country or
        <br />
        An applicant cancels / withdraws his or her application.
        <br />
        If not disqualified from claiming a refund by the above, then the
        consultation Fee may be refunded at Space International’s discretion,
        only if:
        <br />
        The application receives a formal rejection from the institution and any
        application fee paid to institution is refunded by institution for that
        application fee.
      </p>
    </div>
  );
};

export default Refundpolicy;
