import React from "react";
import "./Leadership.css";
import Images from "../../Data/Images";

const Leadership = () => {
  return (
    <div className="leadership">
      <div className="leader-main">
        <div className="leader-content">
          <span>The Leadership Team</span>
          <p>
            Space Internationals newly formed online platform simplifies the
            study abroad search, application, and acceptance process by
            connecting international students, recruitment teams, freelancers
            and academic institutions on one platform.
          </p>
          <p>
            Space Internationals is growing to become the world’s largest online
            platform for international student recruitment, assisting thousands
            of students to be successful in their life and can get best results
            in with their educational journeys as well as in their dream
            carrier. Year 2024, will be the Space Internationals named as one of
            the fastest-growing technology companies in India as well as at
            Globe level.
          </p>
          <p>
            Our team has grown quickly over the past six years, and we now have
            100+ team members across the India and globe. The Space
            Internationals headquarters is located in Patiala, Punjab, India
            with representatives and is going to expend their team network in
            more than 30 other countries including India, Canada, China,
            Vietnam, the Philippines, Nepal, Bangladesh, the United Kingdom,
            Australia, and the United States.
          </p>
        </div>
        <div className="leader-img">
          <img src={Images.Client} alt="leaderimage" />
        </div>
      </div>

      <div className="relationship">
        <div className="support-heading">
          <span>A Message From Our CEO</span>
        </div>
        <div className="testo-slider">
          <div className="testo-img">
            <img src={Images.logo} alt="" />
          </div>
          <div className="testo-content">
            <p>
              “After facing many challenges and obstacles on their own study
              abroad journeys, the CEO saw an opportunity to break
              down barriers and democratize education. Together they created
              Space Internationals to make their dream to educate the world a
              reality did their work in such a transparent way that now Space
              Internationals can say that this company is 100% transparent
              company to safeguard their students, their recruitment teams,
              freelancers, educational institutions and their own staff
              members.”
            </p>
          </div>
        </div>
      </div>

      <div className="jointeam">
        <div className="joinspan">
          <span>Ready To Join Our Team</span>
        </div>
        <div className="joincontact">
          <a href="/Contact">Join Us</a>
        </div>
      </div>
    </div>
  );
};

export default Leadership;
