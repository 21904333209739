import React, { useEffect } from 'react';

const WatiChatWidget = () => {
  useEffect(() => {
    const url = 'https://wati-integration-prod-service.clare.ai/v2/watiWidget.js?82241';
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = url;

    const options = {
      enabled: true,
      chatButtonSetting: {
        backgroundColor: '#0e5094',
        ctaText: 'Eligibility Check',
        borderRadius: '25',
        marginLeft: '10',
        marginRight: '20',
        marginBottom: '20',
        ctaIconWATI: false,
        position: 'left',
      },
      brandSetting: {
        brandName: 'Space Internationals',
        brandSubTitle: 'undefined',
        brandImg: 'https://www.spaceinternationals.in/static/media/logo.f968b121361e7a4c2250.png',
        welcomeText: 'Hi there!\nHow can I help you?',
        messageText: 'Hello, %0A I have a question about {{page_link}}',
        backgroundColor: '#0e5094',
        ctaText: 'Eligibility Check',
        borderRadius: '25',
        autoShow: false,
        phoneNumber: '919056344480',
      },
    };

    script.onload = () => {
      if (window.CreateWhatsappChatWidget) {
        window.CreateWhatsappChatWidget(options);
      }
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
};

export default WatiChatWidget;