import logo from "../Assets/logo.png";
import Client from "../Assets/clients.jpg";
import institutions2 from "../Assets/institutions-second.webp";
import institutions from "../Assets/institutions.jpg";
import institutions1 from "../Assets/institutions1.jpg";
import ourstory from "../Assets/folder/ourstory.png";
import Institute from "../Assets/folder/institutions.png";
import partnersfirst from "../Assets/folder/partners-first.png";
import partners from "../Assets/partners.jpg";
import applicant from "../Assets/Applicant_Matching.webp";
import approve from "../Assets/Approved_Recruiter_Network2.webp";
import datainsights from "../Assets/DataInsights.png";
import Documentverfi from "../Assets/Document_Verification.webp";
import educo from "../Assets/educationeco.png";
import eventweb from "../Assets/EventsWebinars.png";
import impact1 from "../Assets/Impact1.webp";
import impact2 from "../Assets/Impact2.webp";
import impact3 from "../Assets/Impact3.webp";
import insdata from "../Assets/ins-data.png";
import insedu from "../Assets/ins-Edu.png";
import inslocal from "../Assets/Ins-local.png";
import inssec from "../Assets/ins-security.png";
import localsupport from "../Assets/localsupport.png";
import map1 from "../Assets/Map.webp";
import perks1 from "../Assets/Perks1.webp";
import perks2 from "../Assets/Perks2.webp";
import perks3 from "../Assets/Perks3.webp";
import promochannel from "../Assets/Promotional_Channels.webp";
import receivequality from "../Assets/Receive_Quality_Applications.webp";
import rplaptop from "../Assets/RP_Laptop.webp";
import rpgetstarted from "../Assets/RPgetstarted.webp";
import schoolgetstarted from "../Assets/Schoolgetstarted.webp";
import studentdiversity from "../Assets/Student_Diversity.webp";
import stugetstarted from "../Assets/Studentgetstarted.webp";
import stuapply from "../Assets/students_apply_schools.webp";
import stuvisa from "../Assets/students_apply_to_visa.webp";
import stuaccept from "../Assets/students_get_accepted.webp";
import stujourney from "../Assets/students_start_journey.webp";
import stueligibility from "../Assets/stuudents_check_eligibility.webp";
import school from "../Assets/folder/School.webp";
import Student from "../Assets/folder/Student.webp";
import RP from "../Assets/folder/RP.webp";
import RPLap from "../Assets/RP_Laptop.png";
import backvideo from "../Assets/background.mp4";
import usa from "../Assets/folder/usa.jpeg";
import canada from "../Assets/folder/can.jpeg";
import europe from "../Assets/folder/europe.jpeg";
import aus from "../Assets/folder/aus.jpeg";
import uk from "../Assets/folder/uk.jpeg";
import airtickets from "../Assets/folder/airtickets.png";
import tour from "../Assets/folder/tour.png";
import visaservices from "../Assets/folder/visaservices.png";
import acceptance from "../Assets/folder/acceptanceletter.png";
import books from "../Assets/folder/books.png";
import datainsight from "../Assets/folder/datainsights.png";
import luggage from "../Assets/folder/luggage.png";
import events from "../Assets/folder/events.png";
import eligibilty from "../Assets/folder/eligibility.png";
import scholar from "../Assets/folder/scholar.png";
import visa from "../Assets/folder/visa.png";
import localsuppor from "../Assets/folder/localsupport.png";
import nouse from "../Assets/folder/nouse.png";
import college from "../Assets/folder/college.png";
import local from "../Assets/folder/localsupport.png";
import diversity from "../Assets/folder/diversity.png";
import document from "../Assets/folder/document.png";
import application from "../Assets/folder/application.png";
import applican from "../Assets/folder/applicant.png";
import network from "../Assets/folder/network.png";
import promotional from "../Assets/folder/promotional.png";
import care from "../Assets/folder/care.jpg";
import innovating from "../Assets/folder/innovating.jpg";
import customerexp from "../Assets/folder/customer-exp.jpg";
import contract from "../Assets/folder/contract.jpg";
import workfun from "../Assets/folder/workfun.jpg";
import studentsuccess from "../Assets/folder/studentsuccess.jpg";
import health from "../Assets/folder/health.png";
import onboard from "../Assets/folder/onboard.png";
import comp from "../Assets/folder/comp.png";
import competetion from "../Assets/folder/competition.png";
import handshake from "../Assets/folder/handshake.png";


export default {
  logo,
  Client,
  institutions2,
  institutions,
  institutions1,
  ourstory,
  Institute,
  partnersfirst,
  partners,
  applicant,
  approve,
  datainsights,
  Documentverfi,
  educo,
  eventweb,
  impact1,
  impact2,
  impact3,
  insdata,
  insedu,
  inslocal,
  inssec,
  localsupport,
  map1,
  perks1,
  perks2,
  perks3,
  promochannel,
  receivequality,
  rplaptop,
  rpgetstarted,
  schoolgetstarted,
  studentdiversity,
  stugetstarted,
  stuapply,
  stuvisa,
  stuaccept,
  stujourney,
  stueligibility,
  school,
  Student,
  RP,
  RPLap,
  backvideo,
  usa,
  canada,
  europe,
  aus,
  uk,
  airtickets,
  tour,
  visaservices,
  acceptance,
  books,
  datainsight,
  luggage,
  events,
  eligibilty,
  scholar,
  visa,
  localsuppor,
  nouse,
  college,
  local,
  diversity,
  document,
  application,
  applican,
  network,
  promotional,
  care,
  innovating,
  customerexp,
  contract,
  workfun,
  studentsuccess,
  health,
  onboard,
  comp,
  competetion,
  handshake,
};
