import React from 'react'
import './Footer.css'
import Images from '../../Data/Images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faLinkedinIn, faFacebookF, faInstagram, faYoutube} from '@fortawesome/free-brands-svg-icons'

const Footer = () => {
  return (
    <div className='Footer'>
      <div className="footer-main">
        <div className="f-logo">
          <div className="img-logo">
            <img src={Images.logo} alt="logo" />
          </div>
          <div className="logo-address">
            <a href="https://maps.app.goo.gl/YpvKwZ5d9YNRW23v8">Main Market, Street No. 8,<br /> Old Bishan Nagar, Patiala,<br /> Punjab 147001</a>
          </div>
        </div>
        <div className="social">
          <h2>Get Social</h2>
          <div className="social-links">
            <a href=""> <FontAwesomeIcon icon={faLinkedinIn}/> </a>
            <a href=""><FontAwesomeIcon icon={faFacebookF}/></a>
            <a href=""><FontAwesomeIcon icon={faInstagram}/></a>
            <a href=""><FontAwesomeIcon icon={faYoutube}/></a>
          </div>
        </div>
      </div>
      <div className="footer-main1">
        <div className="footer-tags">
          <a href="/Clients">Clients</a>
          <a href="/Partners">Partners</a>
          <a href="/Institutions">Institutions</a>
        </div>
        <div className="f-links">
          <div className="f-heading">
            <span>Explore</span>
          </div>
          <div className="link">
            <a href="/Privacypolicy">Privacy Policy</a>
            <a href="/Termsandconditions">Terms & Conditions</a>
            <a href="/Consultationfee">Consultation Fee</a>
          </div>
        </div>
      </div>
      <div className="footer-main1">
      <div className="f-links">
          <div className="f-heading">
            <span>Discover</span>
          </div>
          <div className="link">
            <a href="/About">Our Story</a>
            <a href="/Careers">Careers</a>
            <a href="/Knowledgehub">Knowledge Hub</a>
            <a href="/Press">Press</a>
            <a href="/Leadership">Leadership</a>
            <a href="/Contact">Contact</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer