import React from "react";
import "./Clients.css";
import Images from "../../Data/Images";
import Faq from "../../Commons/Faq/Faq";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";

const Clients = () => {
  return (
    <div className="clients">
      <div className="client-background">
        <div className="client-back">
          <span1>Your One Stop Solution</span1>
          <br />
          <span>For Internationals Dreams</span>
          <p>
            We'll help you achieve your international dreams and give you the
            hassle free submission.
          </p>
          <a href="/si/student/register.php">Start Your Journey</a>
        </div>
      </div>

      {/* <div className="clients-search">
        <div className="search">
          <input type="text"  placeholder="Search"/>
          <button>Search</button>
        </div>
      </div> */}

      <div className="clients-features">
        <div className="grid-clients">
          <div className="img-clients">
            <img src={Images.Institute} alt="" />
          </div>
          <div className="clients-content">
            <span>
              100,000+ <br />
              Clients Helped
            </span>
          </div>
        </div>
        <div className="grid-clients">
          <div className="img-clients">
            <img src={Images.Institute} alt="" />
          </div>
          <div className="clients-content">
            <span>
              100,000+ <br />
              Visa's Approved
            </span>
          </div>
        </div>
        <div className="grid-clients">
          <div className="img-clients">
            <img src={Images.Institute} alt="" />
          </div>
          <div className="clients-content">
            <span>
              1,000+ <br />
              Institutions
            </span>
          </div>
        </div>
        <div className="grid-clients">
          <div className="img-clients">
            <img src={Images.Institute} alt="" />
          </div>
          <div className="clients-content">
            <span>
              5+ <br />
              Destination Countries
            </span>
          </div>
        </div>
      </div>

      <div className="clients-faq">
        <Faq />
      </div>

      <div className="studyjourney">
        <div className="journey">
          <div className="study-logo">
            <img src={Images.logo} alt="" />
          </div>
          <div className="journey-line"></div>
          <div className="journey-heading">
            <span>Lets Start Your Journey for Your Dream Destination</span>
          </div>
          <div className="journey-tag">
            <a href="/si/student/register.php">Start Your Journey</a>
          </div>
        </div>
      </div>

      <div className="dream-destination">
        <div className="destination">
          <div className="support-heading">
            <span>Study In Your Dream Destination</span>
          </div>
          <div className="destination-grid">
            <div className="country-destination">
              <div className="country-img">
                <img src={Images.canada} alt="" />
              </div>
              <div className="country-span">
                <span>Study in Canada</span>
              </div>
            </div>
            <div className="country-destination">
              <div className="country-img">
                <img src={Images.uk} alt="" />
              </div>
              <div className="country-span">
                <span>Study in UK</span>
              </div>
            </div>
            <div className="country-destination">
              <div className="country-img">
                <img src={Images.usa} alt="" />
              </div>
              <div className="country-span">
                <span>Study in the USA</span>
              </div>
            </div>
            <div className="country-destination">
              <div className="country-img">
                <img src={Images.aus} alt="" />
              </div>
              <div className="country-span">
                <span>Study in Australia</span>
              </div>
            </div>
            <div className="country-destination">
              <div className="country-img">
                <img src={Images.europe} alt="" />
              </div>
              <div className="country-span">
                <span>Study in Europe</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="services">
        <div className="support-heading">
          <span>Everything You Need in One Place</span>
          <p>
            Explore exclusive programs to help you and your students save time
            and money. With Space Internationals, you’re never far from a
            helping hand.
          </p>
        </div>
        <div className="services-grid">
          <div className="service-grid">
            <div className="service-img">
              <img src={Images.airtickets} alt="" />
            </div>
            <div className="service-content">
              <span>Air Tickting (IATA)</span>
              <p>
                We are Authorized Partner with IATA (International Air Transport Association) and we provide Air Tickets at very Afforable Price.
              </p>
              <a href="/Contact">
              Contact Us <FontAwesomeIcon icon={faArrowCircleRight} />
              </a>
            </div>
          </div>
          <div className="service-grid">
            <div className="service-img">
              <img src={Images.tour} alt="" />
            </div>
            <div className="service-content">
              <span>Tour Packages</span>
              <p>
                We provides you the best Tour Package Services for your Dream Destination at Reasonable Rates and Hassle free Service. For More Information Contact Us.
              </p>
              <a href="/si/student/register.php">
                Apply Now <FontAwesomeIcon icon={faArrowCircleRight} />
              </a>
            </div>
          </div>
          <div className="service-grid">
            <div className="service-img">
              <img src={Images.visaservices} alt="" />
            </div>
            <div className="service-content">
              <span>Visa Services</span>
              <p>
                We are best for Visa Services with the success rate of 95%. We are Transparent with our Clients about their Visa Process and we don't take Original Documents.
              </p>
              <a href="/si/student/register.php">
                Apply Now <FontAwesomeIcon icon={faArrowCircleRight} />
              </a>
            </div>
          </div>
          {/* <div className="service-grid">
            <div className="service-img">
              <img src={Images.Client} alt="" />
            </div>
            <div className="service-content">
              <span>Scotia Bank A/C</span>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Non
                quod cum possimus laboriosam dolores distinctio illum, magni,
                voluptates perspiciatis pariatur impedit. Culpa, atque? Odit
                voluptatem ratione dicta corporis iste velit!
              </p>
              <a href="">
                Learn More <FontAwesomeIcon icon={faArrowCircleRight} />
              </a>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Clients;
