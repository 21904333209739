import React from 'react'
import './HowitWorks.css'
import Images from '../../Data/Images'

const HowitWorks = () => {
  return (
    <div className='howitworks'>
      <div className="support-heading">
        <span>How It Works</span>
      </div>
      <div className="howitworks-grid">
        <div className="works-grid">
          <div className="works-img">
            <img src={Images.eligibilty} alt="" />
          </div>
          <div className="works-content">
            <span1>Step 1</span1>
            <span>Students Check Their Eligibility</span>
            <p>Students complete a short survey and get matched to best-fit programs and institutions.</p>
          </div>
        </div>
        <div className="works-grid">
          <div className="works-img">
            <img src={Images.college} alt="" />
          </div>
          <div className="works-content">
            <span1>Step 2</span1>
            <span>Students Apply</span>
            <p>Students select an institution and program, complete their profile, pay their fees, and submit the proper documentation.</p>
          </div>
        </div>
        <div className="works-grid">
          <div className="works-img">
            <img src={Images.acceptance} alt="" />
          </div>
          <div className="works-content">
            <span1>Step 3</span1>
            <span>Students Get Accepted</span>
            <p>The application is reviewed by the institution, and a letter of acceptance is issued.</p>
          </div>
        </div>
      </div>
      <div className="howitworks-grid1">
        <div className="works-grid">
          <div className="works-img">
            <img src={Images.visa} alt="" />
          </div>
          <div className="works-content">
            <span1>Step 4</span1>
            <span>Student Applies for Visa</span>
            <p>The student goes through the visa application process.</p>
          </div>
        </div>
        <div className="works-grid">
          <div className="works-img">
            <img src={Images.luggage} alt="" />
          </div>
          <div className="works-content">
            <span1>Step 5</span1>
            <span>Student Journey Begins</span>
            <p>The student sets off with bags packed ready to start their adventure on your campus.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HowitWorks