import React from "react";
import "./GetStarted.css";
import Images from "../../Data/Images";

const GetStarted = () => {
  return (
    <div className="getstarted">
      <div className="support-heading">
        <span>Get Started with Space International</span>
      </div>
      <div className="getstarted-grid">
        <div className="get-grid">
          <div className="get-img">
            <img src={Images.Client} alt="client" />
          </div>
          <div className="getcontent">
            <span>Clients</span>
            <p>
              Are you a client looking for Immigration services in Canada, the United
              States, the United Kingdom, Australia, or Europe? Let us help you with the Process.
            </p>
            <a href="/si/student/register.php">Client Registration</a>
          </div>
        </div>
        <div className="get-grid">
          <div className="get-img">
            <img src={Images.partners} alt="client" />
          </div>
          <div className="getcontent">
            <span>Partner Inquiry</span>
            <p>
              Become an Space International partner institution to diversify your campus
              by attracting qualified students from around the world. Connect
              with our Partner Relations team here.
            </p>
            <a href="/Resumeform">Partner Inquiry</a>
          </div>
        </div>
        <div className="get-grid">
          <div className="get-img">
            <img src={Images.institutions} alt="client" />
          </div>
          <div className="getcontent">
            <span>Institution Registration</span>
            <p>
              Do you recruit prospective students who want to study in Canada,
              the United States, the United Kingdom, Australia, or Ireland?
              Become an Space International recruitment partner.
            </p>
            <a href="/Institutions">Institution Registration</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
