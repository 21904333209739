import React from "react";
import "./Career.css";
import Images from "../../Data/Images";

const Career = () => {
  return (
    <div className="Career">
      <div className="partner-main">
        <div className="partner-content">
          <span>Help Us Educate the World!</span>
          <p>
            Space Internationals is on a mission to educate the world. We’re
            building a future where anyone, anywhere has access to the best
            education, regardless of where they were born. We want you to be
            part of this movement—and have a whole lot of fun along the way!
          </p>
          <a href="/Resumeform">Contact Us</a>
        </div>
        <div className="partner-img">
          <img src={Images.partnersfirst} alt="" />
        </div>
      </div>

      <div className="ourvalues">
        <div className="support-heading">
          <span>At Space Internationals, We're Committed to Our Values</span>
        </div>
        <div className="ourvalues-grid">
          <div className="value-grid">
            <img src={Images.studentsuccess} alt="" />
            <span>Helping Students Achieve Success</span>
          </div>
          <div className="value-grid">
            <img src={Images.care} alt="" />
            <span>Caring About Each Other</span>
          </div>
          <div className="value-grid">
            <img src={Images.customerexp} alt="" />
            <span>Delivering A+ Customer Experience</span>
          </div>
          <div className="value-grid">
            <img src={Images.contract} alt="" />
            <span>Taking Ownership</span>
          </div>
          <div className="value-grid">
            <img src={Images.innovating} alt="" />
            <span>Innovating and Improving</span>
          </div>
          <div className="value-grid">
            <img src={Images.workfun} alt="" />
            <span>Making Work Fun</span>
          </div>
        </div>
      </div>

      <div className="difference">
        <div className="difference-logo">
          <img src={Images.logo} alt="" />
        </div>
        <div className="difference-span">
          <span>Join Us in Making a Difference!</span>
        </div>
        <div className="difference-tag">
          <a href="/Resumeform">Contact Us</a>
        </div>
      </div>

      <div className="perk-benefits">
        <div className="support-heading">
          <span>Perks And Benefits</span>
        </div>
        <div className="benefits-grid">
          <div className="pb-grid">
            <div className="gridperks">
              <div className="gridi">
                <img src={Images.health} alt="" />
              </div>
              <div className="gridp">
                <span>Health and Wellness</span>
                <p>
                  Comprehensive health and wellness benefits, including dental
                  and vision, an Employee and Family Assistance Program, a
                  personal spending account, and more.
                </p>
              </div>
            </div>
            <div className="gridperks">
              <div className="gridi">
                <img src={Images.onboard} alt="" />
              </div>
              <div className="gridp">
                <span>Exceptional Onboarding</span>
                <p>
                  We build connections from day one with fellow team members
                  while also providing access to technology and vital training
                  and resources needed for a great onboarding experience.
                </p>
              </div>
            </div>
            <div className="gridperks">
              <div className="gridi">
                <img src={Images.comp} alt="" />
              </div>
              <div className="gridp">
                <span>Competitive Compensation</span>
                <p>
                  We offer a generous compensation package that includes an
                  above industry average salary, plus regular performance
                  bonuses.
                </p>
              </div>
            </div>
          </div>
          <div className="pb-grid1">
            <div className="gridperks">
              <div className="gridi">
                <img src={Images.handshake} alt="" />
              </div>
              <div className="gridp">
                <span>Equity</span>
                <p>
                  We are proud to offer company stock options to our team
                  members so they can benefit and thrive along with ApplyBoard.
                </p>
              </div>
            </div>
            <div className="gridperks">
              <div className="gridi">
                <img src={Images.competetion} alt="" />
              </div>
              <div className="gridp">
                <span>Professional Development</span>
                <p>
                  Through learning programs, skill-building workshops, and
                  personalized development plans, we give our team the resources
                  they need for continued growth.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Career;
