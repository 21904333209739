import { Route, Routes } from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar";
import Home from "./Components/Home/Home";
import Footer from "./Components/Footer/Footer";
import About from "./Pages/About/About";
import Clients from "./Pages/Clients/Clients";
import Contact from "./Pages/Contact/Contact";
import Institutions from "./Pages/Institutions/Institutions";
import Partners from "./Pages/Partners/Partners";
import BacktoTop from "./Commons/BacktoTop/BacktoTop";
import Login from "./Pages/Login/Login";
import Signup from "./Pages/Signup/Signup";
import Privacypolicy from "./Pages/Privacypolicy/Privacypolicy";
import Processingfees from "./Pages/Processingfees/Processingfees";
import Termsandconditions from "./Pages/Termsandconditions/Termsandconditions";
import UnlockAccount from "./Commons/Forgetpassword/Unlockaccount";
import ForgetPassword from "./Commons/Forgetpassword/Forgetpassword";
import Leadership from "./Pages/Leadership/Leadership";
import Career from "./Pages/Career/Career";
import Refundpolicy from "./Pages/Refundpolicy/Refundpolicy";
import WatiChatWidget from "./Commons/WatiChatWidget/WatiChatWidget";
import ResumeForm from "./Commons/Form/ResumeForm";

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/Clients" element={<Clients />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Institutions" element={<Institutions />} />
        <Route path="/Partners" element={<Partners />} />
        {/* <Route path="Login" element={<Login />} />
        <Route path="Signup" element={<Signup />} /> */}
        <Route path="/UnlockAccount" element={<UnlockAccount />} />
        <Route path="/ForgetPassword" element={<ForgetPassword />} />
        <Route path="/Privacypolicy" element={<Privacypolicy />} />
        <Route path="/Processingfees" element={<Processingfees />} />
        <Route path="/Termsandconditions" element={<Termsandconditions />} />
        <Route path="/Leadership" element={<Leadership />} />
        <Route path="/Careers" element={<Career />} />
        <Route path="/Consultationfee" element={<Refundpolicy />} />
        <Route path="/Resumeform" element={<ResumeForm />} />
      </Routes>
      <BacktoTop />
      <WatiChatWidget/>
      <Footer />
    </>
  );
}

export default App;
