import React from "react";
import "./Forgetpassword.css";
import Images from "../../Data/Images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";

const Forgetpassword = () => {
  return (
    <div className="Forgetpassword">
      <div className="forget">
        <div className="fp-logo">
          <img src={Images.logo} alt="logo" />
        </div>
        <div className="main-forget">
          <span>Reset Password</span>
          <p>Enter your email address to reset your password</p>
          <input type="email" placeholder="Email" />
          <a href="Login" className="reset">Reset via Email</a>
          <a href="Login" className="backto"> <FontAwesomeIcon icon={faArrowAltCircleLeft} /> Back to Log In</a>
        </div>
      </div>
    </div>
  );
};

export default Forgetpassword;
