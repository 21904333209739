import React, { useRef, useState } from "react";
import "./ResumeForm.css";
import axios from "axios";
import emailjs from '@emailjs/browser';

const ResumeForm = () => {

  const partnerform = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_bf392d8', 'template_sky8c12', partnerform.current, {
        publicKey: 'oQfDu4CIWoqeU8u9v',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  // const [name, setName] = useState('');
  // const [surname, setSurname] = useState('');
  // const [gender, setGender] = useState('');
  // const [email, setEmail] = useState('');
  // const [phone, setPhone] = useState('');
  // const [message, setMessage] = useState('');
  // const [qualification, setQualification] = useState('');
  // const [attachment, setAttachment] = useState(null);

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const formData = new FormData();
  //   formData.append('name', name);
  //   formData.append('surname', surname);
  //   formData.append('email', email);
  //   formData.append('gender', gender);
  //   formData.append('phone', phone);
  //   formData.append('qualification', qualification);
  //   formData.append('message', message)
  //   formData.append('attachment', attachment);

  //   try {
  //     const response = await axios.post('https://spaceinternationals.com/si/partner/newsignup.php', formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data'
  //       }
  //     });
  //     alert('Email sent successfully');
  //   } catch (error) {
  //     console.error('There was an error sending the email!', error);
  //   }
  // };

  return (
    <div className="resumeform">
      <div className="support-heading">
        <span>Fill This Form if you want to BECOME OUR PARTNER!</span>  
      </div>
      <div className="formmm">
      <form className="formm" ref={partnerform} onSubmit={handleSubmit}>
        <div className="resumeinputs">
          <input type="text" placeholder="Name" name="name" required />
          <input type="text" placeholder="Surname" name="surname" required />
        </div>
        <div className="resumeinputs">
          <input type="text" placeholder="Gender" name="gender" required/>
          <input type="email" placeholder="Email" name="email" required />
        </div>
        <div className="resumeinputs">
          <input
            className="phone-input"
            inputmode="numeric"
            pattern="[0-9]*"
            name="mobile"
            placeholder="Mobile Number"
            autocomplete="off"
            maxlength="10"
            required
          />
          <input type="text" placeholder="Qualification" name="qualification" required />
        </div>
        <div className="resumeinputs">
          <input type="text" placeholder="Message" name="message" />
        </div>
        <button>Submit</button>
      </form>
      </div>
    </div>
  );
};

export default ResumeForm;
