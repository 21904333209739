import React, { useRef } from "react";
import "./Institutions.css";
import Images from "../../Data/Images";
import HowitWorks from "../../Commons/HowitWorks/HowitWorks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faWindowRestore,
  faDatabase,
  faSheetPlastic,
} from "@fortawesome/free-solid-svg-icons";
import emailjs from '@emailjs/browser';

const Institutions = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_bf392d8', 'template_rkwjoon', form.current, {
        publicKey: 'oQfDu4CIWoqeU8u9v',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <div className="Institutions">
      <div className="partner-main">
        <div className="partner-content">
          <span>Be Seen by Thousand's of Clients Every Month</span>
          <p>
            Increase your global presence by the number of most qualified students
            from the best platform trusted by thousands of
            institutions worldwide.
          </p>
          <a href="#form">Work With Us</a>
        </div>
        <div className="partner-img">
          <img src={Images.Institute} alt="" />
        </div>
      </div>

      <div className="growth">
        <div className="growth-grid">
          <div className="grid-grow">
            <div className="growth-img">
              <img src={Images.logo} alt="" />
            </div>
            <div className="growth-content">
              <span1>CONVERSION</span1>
              <span>Quality Applications</span>
              <p>
                For every hundred students, we get fifteen more enrolled than
                others.
              </p>
            </div>
          </div>
          <div className="grid-grow">
            <div className="growth-img">
              <img src={Images.logo} alt="" />
            </div>
            <div className="growth-content">
              <span1>DIVERSITY</span1>
              <span>Unmatched Diversity</span>
              <p>
                Space International students come from more than 150 countries.
              </p>
            </div>
          </div>
          <div className="grid-grow">
            <div className="growth-img">
              <img src={Images.logo} alt="" />
            </div>
            <div className="growth-content">
              <span1>PRODUCTIVITY / ROI</span1>
              <span>Less Administration, More Education</span>
              <p>
                Partner institutions spend 25% more time on things that matter.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="commissions">
        <div className="perk-commissions">
          <div className="support-heading">
            <span>Our Impact</span>
          </div>
          <div className="support-para">
            <p>
              Helping connect top institutions with students from around the
              world, and delivering results along the way.
            </p>
          </div>
          <div className="perk-grid">
            <div className="perk">
              <div className="img-perk">
                <img src={Images.impact1} alt="" />
              </div>
              <div className="perk-content">
                <span>50,000+</span>
                <p>Students Helped</p>
              </div>
            </div>
            <div className="perk">
              <div className="img-perk">
                <img src={Images.impact2} alt="" />
              </div>
              <div className="perk-content">
                <span>75+</span>
                <p>Student Source Countries</p>
              </div>
            </div>
            <div className="perk">
              <div className="img-perk">
                <img src={Images.impact3} alt="" />
              </div>
              <div className="perk-content">
                <span>95%</span>
                <p>Success Rate</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="reach">
        <div className="international-reach">
          <div className="support-heading">
            <span>Expanding Our International Reach</span>
          </div>
          <div className="support-para">
            <p>
              Attract the best students from around the world with Space
              International.
            </p>
          </div>
          <div className="reach-grid">
            <div className="grid-container">
              <img src={Images.diversity} alt="" />
              <span>
                Increase Student <br />
                Diversity
              </span>
            </div>
            <div className="grid-container">
              <img src={Images.application} alt="" />
              <span>
                Recieve Quality <br />
                Applications
              </span>
            </div>
            <div className="grid-container">
              <img src={Images.network} alt="" />
              <span>
                Approved Recruiter <br />
                Network
              </span>
            </div>
            <div className="grid-container">
              <img src={Images.document} alt="" />
              <span>
                Document <br />
                Verification
              </span>
            </div>
            <div className="grid-container">
              <img src={Images.promotional} alt="" />
              <span>
                Promotional <br />
                Channels
              </span>
            </div>
            <div className="grid-container">
              <img src={Images.applican} alt="" />
              <span>
                Applicant <br />
                Matching
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="institute-works">
        <HowitWorks />
      </div>

      <div className="easyuse">
        <div className="easytouse">
          <div className="support-heading">
            <span>
              An Easy-to-Use Platform that Connects You to the Right Programs
            </span>
          </div>
          <div className="easytouse-img">
            <img src={Images.RPLap} alt="" />
          </div>
          <div className="easy-platform">
            <div className="easy-grid">
              <FontAwesomeIcon icon={faClock} className="i" />
              <span>Vetted, Offer-Ready Applications, Delivered</span>
              <p>
                Reduce your workload on application processing and follow-ups.
              </p>
            </div>
            <div className="easy-grid">
              <FontAwesomeIcon icon={faSheetPlastic} className="i" />
              <span>Putting Students First</span>
              <p>
                Founded by students for students—we care about every aspect of
                the student journey.
              </p>
            </div>
            <div className="easy-grid">
              <FontAwesomeIcon icon={faWindowRestore} className="i" />
              <span>Flexible Delivery</span>
              <p>
                We match your processes and requirements—there is no need for
                integration.
              </p>
            </div>
            <div className="easy-grid">
              <FontAwesomeIcon icon={faDatabase} className="i" />
              <span>Work With You, For You</span>
              <p>
                Our aim is to free up your time and resources. We help you
                recruit efficiently and effectively.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="ecosystem">
        <div className="support-heading">
          <span>Insights You Can Count On</span>
        </div>
        <div className="ecosystem-caring">
          <div className="ecosystem-grid">
            <div className="eco-img">
              <img src={Images.local} alt="" />
            </div>
            <div className="eco-content">
              <span>Local Support</span>
              <p>
                Our recruitment experts are there to support you
                every step of the way.
              </p>
            </div>
          </div>
          <div className="ecosystem-grid">
            <div className="eco-img">
              <img src={Images.scholar} alt="" />
            </div>
            <div className="eco-content">
              <span>Industry Education</span>
              <p>
                Expanding recruitment partner education through guided online
                courses that strengthen the quality of the applications you
                receive.
              </p>
            </div>
          </div>
          <div className="ecosystem-grid">
            <div className="eco-img">
              <img src={Images.events} alt="" />
            </div>
            <div className="eco-content">
              <span>Events</span>
              <p>
                Training, and Regular events to keep you
                updated on the latest trends and regulations.
              </p>
            </div>
          </div>
          <div className="ecosystem-grid">
            <div className="eco-img">
              <img src={Images.datainsight} alt="" />
            </div>
            <div className="eco-content">
              <span>Data and Insights</span>
              <p>
                Industry-leading insights and knowledge to help you plan,
                expand, and achieve your goals.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="global-presence">
        <div className="support-heading">
          <span>Global Presence for Global Recruitment</span>
        </div>
        <div className="global">
          <div className="global-content">
            <div className="span-container">
              <span>1,200+</span>
              <p>Globally</p>
            </div>
            <div className="span-container">
              <span>250+</span>
              <p>South Asia</p>
            </div>
            <div className="span-container">
              <span>25+</span>
              <p>South and East Asia, Africa and Latin America</p>
            </div>
          </div>
          <div className="global-img">
            <img src={Images.map1} alt="" />
          </div>
        </div>
      </div> */}

      <div className="relationship">
        <div className="support-heading">
          <span>A Relationship Built on Trust and Credibility</span>
        </div>
        <div className="testo-slider">
          <div className="testo-img">
            <img src={Images.logo} alt="" />
          </div>
          <div className="testo-content">
            <p>
              "Space International has helped me significantly increase my
              student reach and gives me more school options to offer my
              students. I also have added advantage of Space International
              assessing student eligibility, which gives me and my students
              peace of mind."
            </p>
            <span>Dhruv Asth</span>
          </div>
        </div>
      </div>

      <div className="work-form">
        <div className="partnership">
          <div className="workform-content">
            <span1>Work With Space International</span1>
          </div>
          <div className="workform-heading">
            <span>Partnership Request</span>
          </div>
          <div className="workform-para">
            <p>Complete the form below and our Team will be in touch soon.</p>
          </div>
        </div>
        <div id="form" className="partnership-form">
          <div className="partner-form">
            <form ref={form} onSubmit={sendEmail}>
              <input className="inputt" name="destination" type="text" placeholder="Destination" required />
              <input className="inputt" name="collegename" type="text" placeholder="College/University Name" required />
              <input className="inputt" name="name" type="text" placeholder="Contact Name" required/>
              <input className="inputt" name="email" type="text" placeholder="Contact Email" required/>
              <input className="inputt" name="number" type="text" placeholder="Phone Number" required/>
              <input className="inputt" name="title" type="text" placeholder="Contact Title" required/>
              <div className="checkbox">
                <input type="checkbox" name="Checked"/>
                <span>
                  Check if you have been referred by someone in Space
                  International
                </span>
              </div>
              <input className="inputt" name="comments" type="text" placeholder="Any Additional Comments" required/>
              <p>
                Space International is committed to protecting and respecting
                your privacy, and we’ll only use your personal information to
                administer your account and to provide the products and services
                you requested from us. From time to time, we would like to
                contact you about our products and services, as well as other
                content that may be of interest to you. If you consent to us
                contacting you for this purpose, please tick below to say how
                you would like us to contact you:
              </p>
              <div className="checkbox">
                <input type="checkbox" name="Checked" required/>
                <span>
                I agree to receive other communications from Space International.
                </span>
              </div>
              <p>
                You can unsubscribe from these communications at any time. For
                more information on how to unsubscribe, our privacy practices,
                and how we are committed to protecting and respecting your
                privacy, please review our Privacy Policy.
              </p>
              <p>
                By clicking submit below, you consent to allow Space
                International to store and process the personal information
                submitted above to provide you the content requested.
              </p>
              <button>Submit</button>
            </form>
          </div>
          <div className="partner-img">
            <img src={Images.institutions2} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Institutions;
