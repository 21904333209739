import React from "react";
import './Privacypolicy.css';

const Privacypolicy = () => {
  return (
    <div className="privacypolicy">
      <span>Privacy and Cookies Policy</span>
      <br />
      <span1>Last Updated: 5 July 2024</span1>
      <p>
      Welcome to Space Internationals. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you visit our website [www.spaceinternationals.com] and use our services, including those related to immigration visa processes. Your privacy is of utmost importance to us, and we are committed to safeguarding your personal data.
<br />
<br />
<span1>1. Introduction</span1>
<br />
<br />
1.1 Space Internationals ("we," "us," "our") respects your privacy and is committed to protecting your personal data. This Privacy Policy will inform you about how we look after your personal data when you visit our website and use our services, and it will tell you about your privacy rights and how the law protects you.
<br />
<br />
1.2 By using our services, you agree to the collection and use of information in accordance with this Privacy Policy. If you do not agree with the terms, please do not use our services.
<br />
<br />
<span1>2. Information We Collect</span1>
<br />
<br />
2.1 Personal Information: When you use our services, we may collect personal information from you, such as:
<br />
<br />
Full name
Date of birth
Gender
Nationality
Passport number
Visa application details
Contact information (address, email, phone number)
Employment history
Educational background
<br />
<br />
2.2 Sensitive Information: In the course of providing immigration visa services, we may collect sensitive information such as:
<br />
<br />
Medical history
Criminal record information
Biometric data (fingerprints, photos)
<br />
<br />
2.3 Technical Data: We may collect technical data about your device and internet usage, including:
<br />
<br />
IP address
Browser type and version
Time zone setting
Browser plug-in types and versions
Operating system and platform
Other technology on the devices you use to access this website
<br />
<br />
2.4 Usage Data: We may collect information about how you use our website and services, including:
<br />
<br />
Pages visited
Time spent on pages
Clicks and scrolling activity
Referring website addresses
<br />
<br />
2.5 Cookies and Tracking Technologies: We use cookies and similar tracking technologies to track the activity on our website and hold certain information. You can control the use of cookies at the individual browser level.
<br />
<br />
<span1>3. How We Use Your Information</span1>
<br />
<br />
3.1 We use the information we collect to:
<br />
<br />
Provide and manage our services
Process and facilitate your visa application
Communicate with you about your application and our services
Verify your identity and eligibility for immigration purposes
Comply with legal and regulatory requirements
Improve our website and services
Perform analytics and conduct research
<br />
<br />
<span1>4. Legal Basis for Processing Your Information</span1>
<br />
<br />
4.1 We rely on the following legal bases to process your personal information:
<br />
<br />
Performance of a contract: To fulfill our contractual obligations to you.
Legal obligation: To comply with applicable laws and regulations.
Legitimate interests: To manage our business and improve our services.
Consent: Where you have provided your consent.
<br />
<br />
<span1>5. Disclosure of Your Information</span1>
<br />
<br />
5.1 We may share your personal information with third parties under the following circumstances:
<br />
<br />
With government authorities and immigration officials for visa processing
With our trusted service providers who assist us in operating our website and providing our services
With legal and regulatory bodies as required by law
With other third parties with your consent or as necessary to fulfill our contractual obligations
<br />
<br />
<span1>6. Data Security</span1>
<br />
<br />
6.1 We implement appropriate technical and organizational measures to protect your personal data from unauthorized access, use, or disclosure. These measures include:
<br />
<br />
Encryption of data in transit and at rest
Access controls and authentication mechanisms
Regular security assessments and audits
<br />
<br />
<span1>7. Data Retention</span1>
<br />
<br />
7.1 We will retain your personal information only for as long as necessary to fulfill the purposes for which it was collected, including for the purposes of satisfying any legal, accounting, or reporting requirements.
<br />
<br />
<span1>8. Your Data Protection Rights</span1>
<br />
<br />
8.1 Depending on your location, you may have the following rights regarding your personal information:
<br />
<br />
Right to access: You have the right to request access to your personal data.
Right to rectification: You have the right to request correction of inaccurate or incomplete data.
Right to erasure: You have the right to request deletion of your personal data.
Right to restrict processing: You have the right to request restriction of processing your personal data.
Right to data portability: You have the right to request transfer of your personal data to another party.
Right to object: You have the right to object to the processing of your personal data.
<br />
<br />
<span1>9. Exercising Your Rights</span1>
<br />
<br />
9.1 To exercise your data protection rights, please contact us at [contact@spaceinternationals.com]. We will respond to your request within the applicable legal time frame.
<br />
<br />
<span1>10. International Data Transfers</span1>
<br />
<br />
10.1 Your personal information may be transferred to, and processed in, countries other than the country in which you are resident. These countries may have data protection laws that are different from the laws of your country. We ensure that appropriate safeguards are in place to protect your personal data when transferred internationally.
<br />
<br />
<span1>11. Changes to This Privacy Policy</span1>
<br />
<br />
11.1 We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
<br />
<br />
<span1>12. Contact Us</span1>
<br />
<br />
12.1 If you have any questions about this Privacy Policy, please contact us:
<br />
<br />
By email: [help@spaceinternationals.com]
By mail: [Space Internationals, Old Bishan Nagar, Main Market, Street no. 8, Patiala, India, 147001]
<br />
<br />
<span1>13. Your Consent</span1>
<br />
<br />
13.1 By using our website and services, you consent to the collection and use of your personal information as described in this Privacy Policy.
<br />
<br />
<span1>14. Governing Law</span1>
<br />
<br />
14.1 This Privacy Policy shall be governed by and construed in accordance with the laws of [Your Country], without regard to its conflict of law principles.
<br />
<br />
<span1>15. Complaints</span1>
<br />
<br />
15.1 If you believe that we have not adhered to this Privacy Policy or you have a complaint about our handling of your personal data, you may contact our Data Protection Officer at [dpo@spaceinternationals.com]. You also have the right to lodge a complaint with a supervisory authority.
<br />
<br />
<span1>16. Third-Party Links</span1>
<br />
<br />
16.1 Our website may contain links to other websites operated by third parties. We are not responsible for the privacy practices or the content of such third-party websites. We encourage you to review the privacy policies of these third-party websites before providing any personal information.
<br />
<br />
<span1>17. Automated Decision-Making</span1>
<br />
<br />
17.1 We do not use automated decision-making, including profiling, that produces legal effects concerning you or similarly significantly affects you.
<br />
<br />
<span1>18. Marketing Communications</span1>
<br />
<br />
18.1 With your consent, we may send you marketing communications about our services. You can opt-out of receiving these communications at any time by following the unsubscribe instructions provided in the communication or by contacting us at [contact@spaceinternationals.com].
<br />
<br />
<span1>19. Data Protection Officer</span1>
<br />
<br />
19.1 We have appointed a Data Protection Officer (DPO) who is responsible for overseeing questions related to this Privacy Policy. If you have any questions about this Privacy Policy or how we handle your personal data, please contact our DPO at [dpo@spaceinternationals.com].
<br />
<br />
<span1>20. Updates to Your Information</span1>
<br />
<br />
20.1 It is important that the personal information we hold about you is accurate and current. Please keep us informed if your personal information changes during your relationship with us.
<br />
<br />
<span1>21. Data Anonymization and Aggregation</span1>
<br />
<br />
21.1 We may anonymize and aggregate your personal data for the purpose of research, statistical analysis, and improving our services. Once anonymized, this data will not identify you personally.
<br />
<br />
<span1>22. Training and Awareness</span1>
<br />
<br />
22.1 We ensure that our employees are trained and aware of their responsibilities regarding the protection of personal data. Regular training sessions are conducted to keep our staff updated on data protection best practices.
<br />
<br />
<span1>23. Data Minimization</span1>
<br />
<br />
23.1 We strive to collect only the personal data that is necessary for the purposes outlined in this Privacy Policy. We ensure that our data collection practices are proportionate and relevant.
<br />
<br />
<span1>25. Consent Withdrawal</span1>
<br />
<br />
25.1 Where we rely on your consent to process your personal data, you have the right to withdraw your consent at any time. To withdraw your consent, please contact us at [contact@spaceinternationals.com]. Withdrawal of consent does not affect the lawfulness of processing based on consent before its withdrawal.
<br />
<br />
<span1>26. Privacy Impact Assessments</span1>
<br />
<br />
26.1 We conduct Privacy Impact Assessments (PIAs) to identify and mitigate risks to your personal data when we introduce new technologies or processes that involve the processing of personal data.
<br />
<br />
<span1>27. Accountability</span1>
<br />
<br />
27.1 We take accountability for our data protection practices and ensure that we are compliant with applicable data protection laws. We regularly review and update our privacy practices to maintain our commitment to data protection.
<br />
<br />
<span1>28. Data Subject Requests</span1>
<br />
<br />
28.1 We provide mechanisms for you to submit requests regarding your personal data. These requests can be made through our website or by contacting us directly. We aim to respond to all requests in a timely and efficient manner.
<br />
<br />
<span1>29. Incident Response</span1>
<br />
<br />
29.1 In the event of a data breach or other security incident, we have an incident response plan in place to promptly address and mitigate any potential harm to your personal data.
<br />
<br />
<span1>30. Transparency</span1>
<br />
<br />
30.1 We are committed to transparency in our data processing activities. This Privacy Policy is designed to provide you with clear and comprehensive information about how we handle your personal data.
      </p>
    </div>
  );
};

export default Privacypolicy;
